import { Controller } from "stimulus";
import tippy, { roundArrow } from 'tippy.js';

export default class extends Controller {
  static values = { customProperties: Object };

  initialize() {
    tippy.setDefaultProps({
      delay: 50,
      placement: 'bottom',
      arrow: roundArrow,
      touch: ['hold', 500],
      popperOptions: {
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        }],
      }
    });

    document.addEventListener(
      "cable-ready:after-morph",
      this.initializeTippy.bind(this),
      true
    );
  }

  connect() {
    this.initializeTippy();
  }

  disconnect() {
    if (this.element._tippy) this.element._tippy.destroy();
  }

  initializeTippy() {
    if (this.element._tippy) this.element._tippy.destroy();
    tippy(this.element, this.customProperties);
  }

  get customProperties() {
    return this.customPropertiesValue || '{}';
  }
}
